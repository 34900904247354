.landing-page {
    background-image: url('../../assets/images/FIFA_v04JustFIELD\ 2.jpg');
    background-position: center;
    background-size: cover;
    // min-height: 100vh;  
    // box-shadow: inset -50px 0px 0px -30px rgba(254, 48, 48, 0.5),inset 50px 0px 0px -30px rgba(254, 48, 48, 0.5);
    .page-wrapper {
        margin: 0 5px;
    }
}

* {
    font-family: "Gesta RG";
}

.center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.overlay {
    position:absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    
}

.gold-frame-2 {
    box-sizing: border-box;

    width: 300px;
    height: 290px;
    left: 38px;

    background: rgba(17, 17, 17, 0.5);
    border: 4px solid #BC913E;
    backdrop-filter: blur(16px);

    border-radius: 8px;

    background-image: url('../../assets/images/Group 1171274691.png');
    background-position: center;

}

.entry-text {
    position: absolute;
    right: 10px;
    bottom: 45px;
}

.question-mark {
    position: absolute;
    left: 122px;
    top: 68px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    font-size: 128px;
}
