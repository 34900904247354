@use '../../base.scss' as *;


.bg-dark-faq {
    overflow-y: scroll;
    background-color: rgba(17, 17, 17, 0.88);
    padding: 10px 10px 0 10px;
    padding-bottom: 10px;
    border-radius: 8px;
}

.faqlanding {
    height: calc((var(--vh, 1vh) * 100) - 145px);
}

.faqfront {
    height: calc((var(--vh, 1vh) * 100) - 350px) !important;
}

.boldfont {
    font-family: "Gesta BD";
}

.whitelink {
    color: #fff;
    text-decoration: underline;
}

a {
    color: #FFFFFF
}
.faq-container a { text-decoration: underline !important;}