@import './../../utils.scss';


.thanks-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-margin {
        margin-top: var(--thanks-top-margin);
    }

    .bottom-margin {
        margin-bottom: var(--thanks-bottom-margin) !important;
    }

    .button-top-margin {
        margin-top: var(--thanks-button-margin) !important;
    }

}