.jingle-ball {

    .game-landing {
        position: relative;
        width: 100%;
        max-width: 415px;
        height: calc((var(--vh, 1vh) * 100) - var(--to-be-removed));
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        img {
            max-width: 100%;
            width: auto !important;
        }

        .title {
            font-family: 'ITC Avant Garde BD';
            font-size: 34px;
            font-weight: 350;
            line-height: 34px;

            &.special-title {
                width: 100%;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.2em;
            }
        }

        .subtitle {
            font-family: 'ITC Avant Garde BD';
            font-size: 13px;
            font-weight: 350;
            line-height: 20px;
            letter-spacing: 0.2em;

        }

        .content {
            font-family: 'Century Gothic BD';
            font-size: 17px;
            line-height: 19px;
        }

        .button {
            min-width: 183px;
            padding: 5px 24px;
            text-align: center;
            background-color: white;
            border: none;
            border-radius: 40px;
            box-shadow: none;
        }

        .button-text {
            font-family: 'Century Gothic BD';
        }

        .content-container {
            display: flex;
            flex-direction: column;

            &.center {
                text-align: center;
            }

            &.left {
                margin-right: auto;
                margin-left: 46px;
            }

            @media (max-height: 630px) {
                zoom: 85%;
            }
            @media (max-height: 550px) {
                zoom: 75%;
            }
        }


    }


}