.deloitte {
    width: 100%;
    height: 100vh;
    padding: 0;
    -webkit-font-smoothing: antialiased;

    .app-holder {
        display: flex;
        flex-direction: column;
        max-width: 415px;
        margin: auto;
        min-height: calc((var(--vh, 1vh) * 100));
    }

    *::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        /* make scrollbar transparent */
    }

}