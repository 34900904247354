@use '../../base';

.footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);

    ul {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        max-width: 415px;
        padding: 0;
        margin: 0 auto;
        list-style: none;

        li {
            width: 100%;

            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 10px 0;

                span {
                    margin-top: 3px;
                    font-size: 10px;
                    letter-spacing: 0.1px;
                    color: #868E96;
                }

                img {
                    height: 22px;
                    margin-bottom: 4px;

                    &.inactive {
                        display: block;
                    }

                    &.active {
                        display: none;
                    }
                }

                &.active {
                    span {
                        color: #FC500E;
                    }

                    img {
                        &.inactive {
                            display: none;
                        }

                        &.active {
                            display: block;
                        }
                    }
                }
            }
        }
    }


}

.footer-hidden {

    display: none;
}