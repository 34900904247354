@use '../../base';

.wango-tango .app-holder {
    .lineup-single-page {
        .page-content {
            padding: 0 50px 0 20px;
        }
    }
}

.lineup-single-page {

    .swiper-slide {
        .image-holder {
            height: 292px;
            filter: base.$drop_shadow_w08_b02;
            border-radius: 20px;
            margin-bottom: 38px;
        }

        h4, p { color: base.$middle_black; }

        h4 { 
            margin: 0 0 3px 0; 
            font-size: 24px;
            line-height: 31px;
            font-family: 'ITC Avant Garde BD';
        }

        p{
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            font-family: 'Century Gothic MD';

            a {
                color: base.$purple;
                font-weight: 600;
            }
        }
    }

}