@use '../../base.scss' as *;


.first-swiper-holder {
    margin-top: 10px;
    background: none;
    width: 80%;

    .swiper {
        height: 100%;

        .swiper-wrapper {
            height: calc((var(--vh, 1vh) * 100) - 330px);
        }

        .swiper-pagination {

            height: 30px;
            width: auto;
            left: 0;
            right: 0;
            z-index: 1;


        }
    }
}

.swiper-slide {
    background: rgba(17, 17, 17, 0.88);
    height: 100% !important;
    overflow-y: scroll !important;
    backdrop-filter: blur(12px);
}

.swiper {

    
    #id0, #id4 {
        background: none;
        backdrop-filter: none;
    }

}

.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
}

.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-prev {
    transform: none !important;

}

.swiper-pagination-bullet {
    background: #fff;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background: #E9BA63;
    opacity: 1;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff;
}

.swiper-pagination {
    bottom: 0px !important;
}

.swiper-button-next {

    z-index: 2;
    background: #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    right: 5px;
    top: calc(50% - 60px);
    color: #A8A8A8;
}

.swiper-button-prev {

    z-index: 2;
    background: #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    left: 5px;
    top: calc(50% - 60px);
    color: #A8A8A8;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 20px;


}

.infopage {
    position: relative;

    .page-wrapper {
        position: relative;
    }
}

.lays .fullMaxwidth img {
    max-width: 100%;
}

.lays .nav h2 {
    font-size: 24px;
}

.customheight-swiper {

    height: calc((var(--vh, 1vh) * 100) - 280px);

}