.country-page {
    display: flex;
    overflow: hidden;
    position: relative;

    video {
        width: 100%;
        position: fixed;
        left: 0;
    }

    .show-video {
        display: block;
        width: 417px;
        height: 600px;
        max-height: calc(var(--vh, 1vh) * 100 - 300px);
        position: fixed;
        z-index: 3;
        overflow: hidden;

        canvas {
            width: 400px;
            height: 400px;

        }
    }

    .hide-video {
        display: none;
    }

    .page-wrapper {
        margin: 0 16px;
        height: calc(100vh - 223px);
        position: relative;
        width: 100%;
    }

    .overlay {
        position: fixed;
        width: 100%;
        /* Full width (cover the whole page) */
        height: 100%;
        min-height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url("../../assets/images/background.png");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
}

.fadeoverlay {
    position: fixed;
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 89%) 36%, rgb(0 0 0 / 0%) 50%, rgba(0, 0, 0, 0) 20%);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    z-index: 2;
}

.zindex {
    z-index: 3;
}

.parent[data-isOpen="true"] {
    position: absolute;
    top: -100%;
    width: 100%;
}

.ChildButton[data-isOpen="true"] {
    display: none;
}

.screentwo,
.hiddenButton {
    opacity: 0;
}

.buttonBut {
    bottom: 115px !important;
}

.gold-color {
    color: #E9BA63 !important;
    font-size: 16px;
    text-align: center;
}

.z4 {
    z-index: 4;
}