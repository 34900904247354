@use '../../base';

.tickets-page {
    .tickets-header { position: relative; }
    
    .swiper-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 139px 0;
        height: 495px;
    
        h1 {
            color: base.$white;
            font-family: 'ITC Avant Garde BD';
            letter-spacing: -0.02em;
        }
        img {
            width:284px;
        }
    }
    
    .swiper-pagination {
        position: absolute;
        z-index: 1;
        bottom: 31px;
        left: 50%;
        transform: translateX(-50%);
    
        .swiper-pagination-bullet {
            width: 5px;
            height: 5px;
            display: inline-block;
            border-radius: 50%;
            background: base.$white;
            margin: 0 6px;
            transition: all .2s;
        }
        
        .swiper-pagination-bullet-active {
            width: 19px;
            border-radius: 2px;
        }
    }
    
    .page-content {
        margin: 25px 0 40px 0;
    }
    
    .main-info {
        margin-bottom: 30px;
        h2, h5, h6 { color: base.$black; }
        h2 { 
            margin: 0 0 11px 0; 
            font-family: 'ITC Avant Garde BD';
            font-size: 34px;
            line-height: 44px; 
        }
        h5 { 
            margin: 0 0 6px 0; 
            font-family: 'Century Gothic BD';
            font-size: 26px;
            line-height: 30px;
            p { @extend h5; }
        }
        h6 { 
            font-family: 'Century Gothic MD';
            font-size: 21px;
            line-height: 26px;
            margin: 0 0 6px 0; 
            font-weight: 400;
            p { @extend h6; }
        }
        p {
            font-size: 16px;
            line-height: 21px;
            font-family: 'Century Gothic MD';
            color: base.$black;
        }
    }
    
    .tickets-buttons {
        display: flex;

        .button {
            box-shadow: -6.17438px -6.17438px 12.3488px #FAFBFF, 6.17438px 6.17438px 12.3488px #A6ABBD;
            .inner { padding: 0 9px 0 24px; }
            span { line-height: 38px; }
            .icon { margin-left: 20px; color: rgba(201, 205, 220, 1);}
           /*  &:active { 
                .inner { box-shadow: inset -4px -4px 6px #FAFBFF, inset 4px 4px 6px #A6ABBD; }
                span, .icon { color: #CFCFCF; }
            } */
        }
    
        // .cta-stub { 
        //     width: 78px;
        //     height: 78px;
        //     display: flex;
        //     align-items: center;
        //     background: linear-gradient(135deg, base.$black_op_04 0%, base.$white_op_04 100%), base.$white_grey_2;
        //     background-blend-mode: soft-light, normal;
        //     border: 0.6px solid base.$white_op_04;
        //     box-sizing: border-box;
        //     box-shadow: -8px -8px 15px base.$white, 8px 8px 15px base.$grey;
        //     border-radius: 100px;
        //     cursor: pointer;
    
        //     h6 {
        //         text-align: center;
        //         color: base.$middle_grey;
        //         font-family: 'ITC Avant Garde BD';
        //         font-size: 13px;
        //         line-height: 15px;
        //         letter-spacing: 0.1px;
        //     }
        // }
    }
}