@use '../../base';

.wango-tango .app-holder {

    .page-header {
        margin: 20px 0;
        display: inline-block;
        padding: 0 30px;
    
        h4 {
            margin-bottom: 5px;
            font-family: 'ITC Avant Garde BD';
            font-size: 24px;
            line-height: 31px;
            letter-spacing: 0.1px;
            color: #222222;
        }
        
        p {
            color: base.$middle_black;
            font-family: 'Gotham';
            font-size: 18px;
            line-height: 22px;
            color: #222222;
        }
    }

}
