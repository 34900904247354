@use '../../base';

.nft-module {

    .nft-image-holder {
        background-repeat: no-repeat;
        position: relative;
        box-shadow: 0px 4px 10px base.$black_op_02;
        cursor: pointer;
        border-radius: 18px;
        overflow: hidden;

        .nft-inner {
            height: 201px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 24px 0 8px 22px;
            box-shadow: inset 5px 5px 8px base.$white_op_05;
            transition: all .2s;

          /*   &:active {
                box-shadow: inset 5px 5px 15px rgb(166 171 189 / 50%), inset -10px -10px 21px rgb(166 171 189 / 80%);
                h4 { color: base.$light_blue; }
            } */
        }
    
        h4, p { margin-left: 5px; }
        h4 {
            font-family: 'ITC Avant Garde BD';
            font-size: 24px;
            line-height: 26px;
            letter-spacing: 0.02em;
            color: base.$white_grey_2;
            width: 60%;
            transition: all .2s;
        }
    
        p {
            font-weight: 700;
            color: base.$white;
            font-size: 8px;
            line-height: 32px;
            z-index: 1;
            font-family: 'Century Gothic MD';
            font-weight: 100;
        }
    }
}