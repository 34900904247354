.deloitte {


    .faq-page {
        display: flex;
        flex-direction: column;
        padding-left: 26px;
        height: calc(var(--vh, 1vh)*100 - 77px);
        background-image: url('./../../assets/images/background.png');
        background-size: cover;
        overflow-y: auto;

        .question {
            color: #00F1FE;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-weight: 600;
            margin-right: 30px;
        }

        .answer {
            color: #FFFFFF;
            margin-left: 28px;
            margin-right: 30px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-style: normal;
            line-height: 18px;
        }

        .rotated {
            transform: rotate(90deg)
        }

        a {
            color: #FFFFFF;
            font-family: 'Inter';
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            font-style: normal;
            text-decoration: underline;
        }

    }

    body {
        overflow-y: auto;
    }

}