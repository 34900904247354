.highlights-single-page {
    .page-content {
        figure {margin-left: 0; margin-right: 0;}
        section {
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}