.wango-tango .app-holder {

    .swiper-holder {
        position: relative;
    
        h4 {
            font-family: 'Century Gothic BD';
            font-size: 30px;
            line-height: 37px;
            color: #222222;
        }
    }

}

