@use '../../App.scss' as *;

.hex-container {
    .hex-button {
        background-image: url('../../assets/images/hex-back-new.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        .hex-btn-text {
            color: white;
            text-align: center;
            position: absolute;
            // bottom: -20px;
            left: 50%;
            font-size: 16px !important;
            transform: translateX(-50%);
        }

        .hex-btn-img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin: auto;
        }

        .gold-divider {
            min-width: 8px;
            height: 2px;
            max-height: 2px !important;
            background: linear-gradient(180deg, #A87D00 0%, #FFC32F 31.77%, #C9A250 63.54%, #835817 100%);
            transform: rotate(90deg);
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

}