.video-single-page {

    .video-player-holder {
        padding-bottom: 24px;
        border-bottom: 2px solid rgba(163, 173, 178, 0.5);

        &:first-of-type {
            .video-player-title { 
                h5, .button {margin: 0 0 18px 0; }
            }
        }
        
        .video-player-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                font-family: 'Century Gothic BD';
                margin: 18px 0;
            }

            .button {
                min-width: 136px;
                .inner {
                    padding: 0 10px;
                }
                span {
                    font-size: 14px;
                    line-height: 26px;
                }
                .icon {
                    display: none;
                }
            }
        }

        .video-player {
            border-radius: 18px;
            overflow: hidden;
        }
    }


}