@use './base';

.wango-tango {
  width: 100%;
  background-color: base.$white_grey;
  min-height: 100vh;

  h1, h2, h3, h4, h5, h6 { margin: 0; letter-spacing: -0.03em; font-weight: 700; }

  .top-margin {
    margin-top: 44px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .app-holder {
    padding-bottom: 100px;
    max-width: base.$max_width;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;

    button {
      border: none;
      cursor: pointer;
    }
    .image-counter
    {
      font-size:12px; font-family: "Century Gothic MD";
    }
    .asset-caption
    {
      font-size:14px; line-height: 18px; font-family: "Century Gothic MD";
    }
    .asset-credit
    {
      width: 100%;
      display: block;
      font-size: 10px;
    }
    a { text-decoration: none; }

    .content-detail-container
    {
      a {
        text-decoration: none !important;
        color: base.$purple;
        font-weight: bold;
      }
    }
        
    h1 { font-size: 50px; line-height: 64px; }
    h2 { font-size: 42px; line-height: 40px; }
    h3 { font-size: 34px; line-height: 44px; }
    h4 { font-size: 26px; line-height: 33px; }
    h5 { font-size: 21px; line-height: 26px; }
    h6 { font-size: 14px; line-height: 17px; }
    p { margin: 0; font-size: 14px; line-height: 18px;     font-family: "Century Gothic MD";}
    
    .blue {
      color: #1E92FD;
    }

    .purple {
      color: base.$purple;
    }
    
    .brown {
      color: base.$brown;
    }

    .black-grey {
      color: #3C3E56;
    }

    .black-grey-icon {
      .icon { color: #3C3E56; }
    }
    
    .image-holder {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    
    * {
      box-sizing: border-box;
    }

    .page-content {
      padding: 0 30px;
    }

    .module {
      padding: 0 24px;
      margin-bottom: 34px;

      &.full {
        padding: 0;
      }
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      flex-shrink: 0;
    }

  }
}
