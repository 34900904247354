.lays {
    .popup-page {
        background: rgba(17, 17, 17, 0.88);
        backdrop-filter: blur(12px);
        flex-direction: column;
        justify-content: center;
        position: fixed;
        width: 100%;
        z-index: 3;

        .popup-container {

            height: 100% !important;
            overflow-y: scroll !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 414px;
            margin: 0 auto;
        }

        .warning-img {
            height: 120px;
            margin: auto;
            margin-top: 12px !important;
            margin-bottom: 12px !important;
        }

        .popup-text {
            font-family: 'Trebuchet MS';
            font-weight: 400;
            font-size: 14px;
            margin-left: 48px;
            margin-right: 48px;
            text-align: center;
            margin-bottom: 32px;
        }
        .show-true {display: block;}
        .show-false {display: none;}
        .top-button {margin-bottom:20px !important;}
    }
}