label {
    color: #F9B93E;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 3px;

}

.errorcolor {
    color: red;
}

input {
    background-color: #353535;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    font-family: 'Trebuchet MS';
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
}

.form-container {
    width: 100%;
    display: grid !important;
    grid-template-columns: [col] calc(50% - 5px) [col] calc(50% - 5px) [col];
    grid-column-gap: 10px;
    padding: 20px;
}

.form-element {
    height: 54px;
    width: 100%;
    margin: auto;
    margin-top: 16px;
    grid-column: col / span 2;

}

.halfboxfirst {
    grid-column: 1 / span 1;
    grid-row: row 4;
}

.halfboxsecond {
    grid-column: 2 / span 1;
    grid-row: row 4;
}

.doubleContainer {
    // display: grid;
    // grid-template-columns: 200px 200px; 
    // grid-column-gap: 16px;
    // margin:auto;
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 16px;
    width: 87%;
    justify-content: flex-end;
}

.personalInfo {
    .btn {
        width: 87%;
        margin: auto;
        margin-top: 16px;
        z-index: inherit;
    }

}

.bg-dark-form {
    background-color: rgba(17, 17, 17, 0.88);
    padding: 10px 10px 10px 10px;
    border-radius: 8px;
}

.form-img {
    width: 100%;
}

.formheight {
    min-height: calc((var(--vh, 1vh) * 100) - 115px);
    height: calc((var(--vh, 1vh) * 100) - 115px);
    overflow: scroll;
}