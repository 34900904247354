@use '../../base';

.guide-page {
    .guide-header {
        height: 276px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 45px 32px 29px 0;
        position: relative;

        h3 { 
            font-family: 'ITC Avant Garde BD';
            font-size: 50px;
            letter-spacing: -1px;
            line-height: 80px;
            position: absolute;
            left: 15px;
            bottom: 16px;
            color: rgba(233, 235, 239, 1);
        }

        img { width: 159px; }
    
        .button {
            background: linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #EBECF0;
            box-shadow: 0px 4px 4px base.$black_op_02;
            border-radius: 30px;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.1em;
            color: #3B415E;

            .inner {
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                padding: 0 14px 0 17px;
            }

            .icon {
                font-size: 16px;
                color: #3B415E;
            }

            span {
                font-family: 'ITC Avant Garde BD';
                font-size: 16px;
                line-height: 35px;
            }
        }
    }

    .page-content {
        margin: 25px 0 40px 0;
    }
    
    .main-info {
        margin-bottom: 5px;
        h4, h6, span { color: base.$black; }
        p { font-family: 'Century Gothic MD'; font-weight: 400; }
        h3 {
            margin-bottom: 4px;
            font-size: 26px;
            line-height: 33px;
            letter-spacing: -0.03em;
            font-family: 'ITC Avant Garde BD';
            color: base.$middle_black;
        }
        h4 {
            color: base.$black;
            margin-bottom: 4px;
            font-size: 21px;
            line-height: 26px;
            font-family: 'Century Gothic BD';
            font-weight: 400;
            p { @extend h4; }
        }
        h6 {
            color: base.$black;
            margin-bottom: 11px;
            font-size: 14px;
            line-height: 17px;
            font-family: 'Century Gothic BD';
        }
        p { 
            font-size: 13px;
            line-height: 18px;
            color: base.$middle_black;
        }
    }

    .top-swiper {
        max-width: 280px;
        padding-right: 11px;
        margin-bottom: 18px;
        .swiper-slide {
            cursor: pointer; 
            padding: 10px;
            
            span {
                font-family: 'Century Gothic MD';
                font-size: 18px;
                line-height: 22px;
            }

            .active {
                position: relative;
                font-family: 'Century Gothic BD';
                &::after {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: base.$black;
                    bottom: -8px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .bottom-swiper {
        max-width: 280px;
        .swiper-content {
            display: flex;
            height: 299px;
            padding: 31px 20px;
            border-radius: 20px;
            overflow: auto;
            cursor: pointer;

            &.top-left { align-items: flex-start; justify-content: flex-start; }
            &.top-right { align-items: flex-start; justify-content: flex-end; }
            &.bottom-left { align-items: flex-end; justify-content: flex-start; }
            &.bottom-right { align-items: flex-end; justify-content: flex-end; }    
            
            h2 {
                text-align: right;
                color: base.$white;
                font-family: 'ITC Avant Garde BD';
                font-size: 42px;
                line-height: 36px;
            }
        }
    }
}