.jingle-ball {

    .my-stuff {
        display: flex;
        align-items: center;
        height: 53px;
        width: 321px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 120px;
        box-shadow: 6px 6px 10px rgba(82, 82, 82, 0.65);
    
        .title {
            padding-left: 18px;
            margin-right: auto;
            font-family: 'ITC Avant Garde BD';
            font-size: 23px;
            line-height: 30px;
            color: #FFFFFF;
        }
    }

}