.country-selectror-container {

    height: calc((var(--vh, 1vh) * 100) - 320px) !important;
    background: rgba(17, 17, 17, 0.4);
    backdrop-filter: blur(12px);
    border-radius: 8px;
    overflow-y: auto;
    justify-content: space-between;
    padding: 10px 20px;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.flagid-0 p {
    visibility: hidden;
}

.checkmark {
    background-color: #10C700;
    padding: 3px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 100px;
    color: white;
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 25;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.buttoner {
    position: relative;
    width: 33%;
    text-align: center;
}