@use '../../base';

.ar-shop-page {

    .ar-games-holder {
        display: flex;
        flex-direction: column;

        .ar-game {
            height: 173px;
            width: 100%;
            border-radius: 18px;
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 25px 28px 20px 18px;
            cursor: pointer;
            filter: drop-shadow(-5px -5px 8px base.$white_op_08) drop-shadow(3px 3px 8px base.$black_op_02);
            box-shadow: -10px -10px 8px rgba(250, 251, 255, 0.7), 5px 5px 10px rgba(166, 171, 189, 0.6);

            h4 {
                font-size: 26px;
                line-height: 28px;
                font-family: 'ITC Avant Garde BD';
                p {@extend h4}
            }

            .button {
                margin-top: 15px;
                align-self: flex-end;
                color: #3C3E56;
                .icon { color: #3C3E56; }
                box-shadow: none;
                &.inside-button { box-shadow: none; }
            }
        }
    }
}