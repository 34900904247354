.ellipse {
    box-sizing: border-box;

    width: 218px;
    height: 218px;

    border-radius: 200px;

    border: 1px dashed #FFFFFF;

    background-image: url('../../../assets/images/Soccer_ball_Solo_00000 3.png');
    background-position: center;

    filter: drop-shadow(0px 4px 24px #906D2A);
}

.circle {
    position: absolute;
}

.img-height {
    max-height: calc((var(--vh, 1vh) * 95) - 470px) !important;
    max-width: 100%;
    margin: auto;
}