:root {
    --congestion-top-margin: 34px;
    --congestion-small-bottom-margin: 28px;

    --choices-top-margin: 100px;
    --choices-bottom-margin: 56px;

    --thanks-top-margin: 100px;
    --thanks-bottom-margin: 44px;
    --thanks-button-margin: 86px;

    --final-top-margin: 44px;
    --final-image-container-top-margin: 50px;

    @media only screen and (max-height: 500px) {
        --congestion-top-margin: 16px;
        --congestion-small-bottom-margin: 14px;

        --choices-top-margin: 50px;
        --choices-bottom-margin: 27px;

        --thanks-top-margin: 50px;
        --thanks-bottom-margin: 22px;
        --thanks-button-margin: 43px;

        --final-top-margin: 22px;
        --final-image-container-top-margin: 25px;
    }
}