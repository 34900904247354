@use '../../base';

.nft-page {
    .page-content {
        display: flex;
        align-items : center;
        flex-direction: column;

        .nft {
            margin: 27px 0 30px 0;
            border-radius: 11px;
            overflow: hidden;

            .image-holder {
                height: 392px;
                padding: 18px 37px 0 23px;
                box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
                
                h3 { 
                    font-size: 38px;
                    line-height: 40px; 
                    font-family: 'ITC Avant Garde BD';
                    color: base.$white;
                }
            }

            .additional-info {
                padding: 20px 0;
                background-color: #E23632;

                h5 {
                    font-size: 18px;
                    line-height: 26px;
                    font-family: 'ITC Avant Garde BD';
                    color: base.$white;
                    text-align: center;
                    letter-spacing: 0.1px;
                }
            }
        }
    }
}