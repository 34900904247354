@use '../../base';

.lineup-page {

    .lineup-element {
        height: 165px;
        filter: base.$drop_shadow_w08_b02;
        border-radius: 20px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 16px;
        padding: 15px 23px;
        cursor: pointer;
        transition: all .2s;

       /*  &:active {
            box-shadow: inset 5px 5px 15px rgba(166, 171, 189, 0.5), inset -10px -10px 21.0807px rgba(166, 171, 189, 0.8);
        } */

        h4 {
            text-align: right;
            color: base.$white;
            margin: 0;text-align: right;
            width: 100%;
            font-family: 'ITC Avant Garde BD';
            font-size: 28px;
            line-height: 36px;
        }
    }
}