@use '../../base';

.video-page {

    .video-element {
        height: 300px;
        display: flex;
        filter: base.$drop_shadow_w08_b02;
        border-radius: 20px;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
        padding: 18px;
        cursor: pointer;
        position: relative;

        span {
            position: absolute;
            font-size: 70px;
            line-height: 63px;
            letter-spacing: -0.05em;
            color: base.$white;
            font-family: 'ITC Avant Garde BD';

            &.top-left { top: 15x; left: 15px; }
            &.top-right { top: 0px; right: 5px; }
            &.bottom-left { bottom: 0; left: 0; }
            &.bottom-right { bottom: 15px; right: 15px; text-align: right; }
        }

        .icon {
            margin: auto;
            font-size: 58px;
            color: #FFFFFF;
        }
        :nth-of-type(3) {
            display: none;
        }
    }
  

}