@use '../../../base';

.swiper-nav-buttons {
    position: absolute;
    right: 0;
    top: -5px;
    display: flex;
    align-items: center;
    height: 44px;

    .swiper-nav-button {
        height: 37px;
        width: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;     
        cursor: pointer;
        // background: #F0F0F3;
        // border: 2px solid !important;
        box-shadow: -3px -3px 10px #FFFFFF, 3px 3px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
        // border-image-source: linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
        // background-blend-mode:overlay, normal;
        background-position: center;
        background-size: 130%;

        &.swiper-button-prev {
            margin-right: 10px;
            background-image: url("../../../assets/images/LR-Active-Button-State-left.png");
          /*   &:active { 
                background-image: url("../../../assets/images/Pressed-LR-Button-State-left.png"); 
                box-shadow: none
            } */
        }
        &.swiper-button-next {
            margin-right: 10px;
            background-image: url("../../../assets/images/LR-Active-Button-State-right.png");
           /*  &:active { 
                background-image: url("../../../assets/images/Pressed-LR-Button-State-right.png"); 
                box-shadow: none
            } */
        }
    
        .swiper-icon {
            font-size: 16px;
            color: base.$grey;
            display: none;
        }
    }
}
