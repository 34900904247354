@use '../../App.scss' as *;

.hex-container {
    .hex-button {
        background-image: url('../../assets/images/hex-back-new.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        .hex-btn-text {
            color: white;
            text-align: center;
            position: absolute;
            // bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
        }

        .hex-btn-img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin: auto;
        }

        .gold-divider {
            min-width: 8px;
            height: 2px;
            max-height: 2px !important;
            background: linear-gradient(180deg, #A87D00 0%, #FFC32F 31.77%, #C9A250 63.54%, #835817 100%);
            transform: rotate(90deg);
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

}

.what {
    color: white
}

.match-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
}

.card-background {
    background-color: #1B1B1B;
    margin-bottom: 0px;
    margin-top: 0px;
}

.match {
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    font-family: 'Gesta RG';
}

.score {
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    font-family: 'Gesta RG';
    display: flex;
    flex-direction: row;
    gap: 4px
}

.winner {
    font-family: 'Gesta BD';
}

.information-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: center;
    align-items: center;
}

.lays hr {
    border-top: 0px;
    border-color: white;
    width: 100%;
}

.date-selection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;

}

.date-label {
    font-family: 'Gesta RG';
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 20px;
}

.match-container {
    background: rgba(17, 17, 17, 0.88);
    height: 100% !important;
    overflow-y: scroll !important;
}

.info-page-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.no-match-message {
    font-family: 'Gesta RG';
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 20px;
}

.no-match {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.info-slide2-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px;
}

.colored-text-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.colored-text {
    color: #E9BA63;
}

.entries-notice-text {
    align-self: center;
}

.main-text {
    font-family: 'Trebuchet MS';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
}

.sub-text {
    font-family: 'Trebuchet MS';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}