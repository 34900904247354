@use '../../base';

.wango-tango .app-holder {

    .ar-swag-shop {
        height: 237px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        padding: 52px 24px 19px 20px;
        cursor: pointer;
    
        h2 {
            color: base.$white;
            width: 40%;
            text-shadow: 0px 4px 4px base.$brown_op_01;
            font-size: 38px;
            line-height: 36px;
            font-family: 'ITC Avant Garde BD';
            text-align: right;
        }
    }

}
