@import './../../utils.scss';

.final-page {
    .image-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;
        gap: 37px;
        margin-top: var(--final-image-container-top-margin);

    }

    // .extra-space {
    //     margin-top: 50px;
    // }

    .top-margin {
        margin-top: var(--final-top-margin);
    }

}