@use '../../base';

@mixin shadows ($pos_grad_1, $pos_grad_2, $pos_lin_grad_1, $pos_lin_grad_2) {
    background-image:-webkit-gradient(linear, $pos_grad_1, $pos_grad_2, from(base.$black_op_05), to(base.$black_op_00));
    background-image:-webkit-linear-gradient($pos_lin_grad_1, base.$black_op_05, base.$black_op_00);
    background-image:-o-linear-gradient($pos_lin_grad_1, base.$black_op_05, base.$black_op_00);
    background-image:linear-gradient($pos_lin_grad_2, base.$black_op_05, base.$black_op_00)
}

.photo-collections-single-page {

    .page-header {
        margin: 20px 0 4px 0 !important;
    }

    .collection-sections {
        margin-bottom: 25px;
        
        .section {
            font-size: 14px;
            line-height: 24px;
            color: base.$black;
            padding: 4px 12px;
            background-color: base.$white;
            border-radius: 24px;
            cursor: pointer;
            font-family: 'Century Gothic BD';
            letter-spacing: 0.2px;

            &.active {
                color: base.$white;
                background-color: #494949;
            }
        }

        .years {
            display: flex;
            justify-content: center;
            margin-top: 7px;

            span {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 4px;
                font-family: 'Century Gothic BD';
                color: base.$black;
                background-color: base.$white;
                font-size: 14px;
                line-height: 28px;
                text-align: center;
                cursor: pointer;
                letter-spacing: -1px;

                &:last-of-type { margin-right: 0; }

                &.year-active {
                    background-color: #494949;
                    color: base.$white;
                }
            }
        }
    }

    .selected-collection {
        display: flex;
        flex-direction: column;
        padding: 0 30px;

        .swiper {
            overflow-y: hidden;
            height: 412px;
        }
    
        .image-holder {
            height: 216px;
            position: relative;

            .image-caption {
                position: absolute;
                bottom: 0;
                padding: 9px;
                width: 100%;
                background-color: base.$black_op_07;

                p { 
                    color: base.$white;
                    text-align: center;
                    font-family: 'Century Gothic BD';
                    font-size: 10px;
                    letter-spacing: 0.1px;
                }
            }
        }
    
        .swiper-container {
            overflow: hidden;
        }
        .swiper-wrapper {
            display: block !important;
            position: relative;
        }
        .swiper {
            -webkit-perspective: 1390px;
            perspective: 1390px;

            .swiper-slide {
                border-radius: 24px;
                overflow: hidden;
                width: 100%;
                height: 216px;
                opacity: 0.3;

                &.swiper-slide-active {opacity: 1;}
                &.swiper-slide-next, &.swiper-slide-prev { opacity: 0.7; }
                &.swiper-slide-duplicate-prev, &.swiper-slide-duplicate-next { opacity: 0.3; }
                &.swiper-slide-duplicate-active { opacity: 0; }
            }

            .swiper-slide, .swiper-slide-shadow-top, .swiper-slide-shadow-bottom, .swiper-wrapper {
                -webkit-transform-style:preserve-3d;
                transform-style: preserve-3d;
            }

            .swiper-slide-shadow-top, .swiper-slide-shadow-bottom {
                position:absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
                pointer-events:none;
                z-index:10
            }

            .swiper-slide-shadow-top{
                @include shadows(top right, top left, top, to top);
            }

            .swiper-slide-shadow-bottom{
                @include shadows(bottom left, bottom right, bottom, to bottom);
            }
        }
    
        .swiper-pagination {
            position: absolute;
            z-index: 1;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
        
            .swiper-pagination-bullet {
                width: 5px;
                height: 5px;
                display: inline-block;
                border-radius: 50%;
                background: base.$white_grey_4;
                margin: 0 6px;
                transition: all .2s;
            }
            
            .swiper-pagination-bullet-active {
                width: 19px;
                border-radius: 2px;
                background: base.$dark_grey;
            }
        }

        .button {
            margin: 20px auto 0 auto;
            .inner {
                padding: 0 17px;
            }
            span {
                font-size: 17.2px;
                line-height: 32px;
            }
            .icon {
                display: none;
            }
        }
    }

}