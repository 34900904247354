.webcam-btn {
    all: unset;

    min-height: 60px;
    min-width: 60px;
    padding: 5px;
    background-image: url('../../assets/images/webcam-btn.png');
    background-size: contain;
    vertical-align: middle;
    margin: 0 auto;
}

.webcam-btn-div {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    padding: 30px;
    justify-content: center;
    text-align: center;
}

.invalidPhoto {
    position: fixed;
    bottom: 200px;
    width: 200px;
    margin-bottom: 20px;
}


.webcam-img {
    background-color: #181818;
    width: 345px;
    height: 657px;
}

.webcam-img::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 100px;
    right: 0;
    bottom: 0;
}

.abs-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.hexagonal-div {
    overflow: hidden;
    width: 90%;
    height: 70%;
    background: rgba(117, 117, 117, 0.6);
    box-sizing: border-box;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    position: absolute;
}

.playinline {
    width: 100%;
    height: auto;
}

.cam-img {
    position: absolute;
    top: 2px;
    left: 2px;
    width: auto;
    height: auto;
    -webkit-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
    -moz-clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.hex-img {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: auto;
    top: 0;
}

.webcam-btns {
    position: fixed;
    bottom: 70px;
    width: 100%;
    left: auto;
}

.webcam-div {
    width: calc((var(--vh, 1vh) * 100) - 400px);
    max-width: 100%;
}

.pos-rel {
    position: relative;
}