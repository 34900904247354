.lays .home-page {
    background-image: url('../../../assets/images/background-home.jpg');
    background-position: center;
    background-size: cover;
    height: 100%;

    .page-wrapper {
        margin: 0;
        padding-bottom: 70px;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: auto !important;
        overscroll-behavior: none;
        height: 100%;
    }

    .grid-container {
        display: grid;
        align-content: space-between;
        justify-content: space-around;
        grid-template-columns: auto auto;

    }

    .two-grid-container {
        display: grid;
        grid-auto-flow: column;
    }
}

.show-false {
    display: none;
}