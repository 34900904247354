$white: #FFFFFF;
$white_grey: #EEEFF3;
$white_grey_2: #E7EAF3;
$white_grey_3: #DADADA;
$white_grey_4: #CFCFCF;
$white_pink: #FFD9DD;

$light_purple : #8885FF;
$purple: #755EC6;

$orange: #FC500E;
$red: #C2002A;

$red_op_05 : #C2002A80;

$light_blue: #83CCF8;
$strong_blue: #0500FF;

$brown: #AB6767;
$dark_brown: #725559;

$light_grey: #BDC1CF;
$grey: #A3ADB2;
$middle_grey: #757575;
$dark_grey: #616161;

$black: #000000;
$light_black : #333333;
$middle_black : #222222;

$grey_op_03: rgba(174, 174, 192, 0.3);
$grey_op_06: rgba(174, 174, 192, 0.6);

$black_op_00: rgba(0, 0, 0, 0);
$black_op_01: rgba(0, 0, 0, 0.1);
$black_op_02: rgba(0, 0, 0, 0.2);
$black_op_03: rgba(0, 0, 0, 0.3);
$black_op_04: rgba(0, 0, 0, 0.4);
$black_op_05: rgba(0, 0, 0, 0.5);
$black_op_07: rgba(0, 0, 0, 0.7);

$white_op_00: rgba(255, 255, 255, 0);
$white_op_02: rgba(255, 255, 255, 0.2);
$white_op_04: rgba(255, 255, 255, 0.4);
$white_op_05: rgba(255, 255, 255, 0.5);
$white_op_07: rgba(255, 255, 255, 0.7);
$white_op_08: rgba(255, 255, 255, 0.8);
$white_op_09: rgba(255, 255, 255, 0.9);

$brown_op_01: rgba(141, 16, 16, 0.1);

$drop_shadow_w08_b02: drop-shadow(-5px -5px 10px $white_op_08) drop-shadow(5px 5px 8px $black_op_02);
$drop_shadow_w08_b04: drop-shadow(-5px -5px 10px $white_op_08) drop-shadow(5px 5px 8px $black_op_04);

$max_width: 414px;

$exp_purple_grad: linear-gradient(180deg, rgba(245, 137, 255, 0.4) 49%, rgba(196, 70, 255, 0.8) 100%);
$exp_ligh_green_grad: linear-gradient(180deg, rgba(0, 255, 190, 0.35) 49.1%, #035A44 100%);
$exp_fade_purple_grad: linear-gradient(180deg, rgba(149, 126, 161, 0.35) 49.1%, #9B82A6 100%);
$exp_blue_grad: linear-gradient(180deg, rgba(105, 152, 209, 0.48) 49.1%, #3E66A3 100%);
$exp_green_grad: linear-gradient(180deg, rgba(0, 255, 190, 0.35) 98.19%, #035A44 149.09%);

@font-face {
    font-family: 'Century Gothic MD';
    src: local('Century Gothic MD'), url(./assets/fonts/century-gothic/CenturyGothic.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic BD';
    src: local('Century Gothic BD'), url(./assets/fonts/century-gothic/gothicb.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Avant Garde BD';
    src: local('Century Gothic BD'), url('./assets/fonts/itc-avant-garde-gothic-lt/ITC Avant Garde Gothic LT Bold.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url('./assets//fonts/Gotham-Font/GothamBook.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}