@use '../../base';

.button {
    color: base.$dark_grey;
    background: linear-gradient(135deg, base.$black_op_04 0%, base.$white_op_04 100%), base.$white_grey;
    border: 0.7px solid base.$white_op_04;
    box-shadow: -8px -8px 16px base.$white_grey, 5px 5px 8px base.$black_op_02;
    background-blend-mode: soft-light, normal;
    border-radius: 30px;
    z-index: 1;
    

    .inner {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px 0 16px;
        border-radius: 30px;
        box-shadow: inset 0 0 0 base.$black_op_02, inset 0 0 0 rgba(0, 0, 0, 0.05);
        transition: all .2s;
    }

    span {
        font-family: 'ITC Avant Garde BD';
        font-size: 21px;
        line-height: 44px;
        letter-spacing: 0.03em;
    }

    .icon { 
        margin-left: 10px; 
        color: base.$grey; 
        font-size: 23px; 
        transition: all .2s;
    }

  /*   &:active{ 
        background: linear-gradient(135deg, base.$black_op_04 0%, base.$white_op_04 100%), #EBECF0;
        box-shadow: none;
        border: 0.7px solid #EAEAEA;
        color: base.$white_grey_4;

        .inner { box-shadow: inset -6px -6px 6px base.$black_op_02, inset 6px 6px 8px rgba(0, 0, 0, 0.05); }
    } */
}

.inside-button {
    box-shadow: unset;
    border: 0.5px solid base.$white_op_04;

    span {
        font-size: 16px;
        line-height: 34px;
    }

    .icon { 
        color: base.$light_grey; 
        font-size: 16px; 
    }

   /*  &:active{ 
        background: linear-gradient(135deg, base.$black_op_04 4%, base.$white_op_04 96%), #EAECF0;
        border: 0.3px solid base.$white_op_04;
        color: base.$white_grey_4 !important;

        .inner { box-shadow: inset -3px -3px 5px base.$white, inset 3px 3px 5px base.$grey; }
        .icon { color: base.$white_grey_4 !important; }

    } */
}

.full-size {
    width: 100%;
    span {
        line-height: 48px;
    }
}
