@use './base' as *;

.jingle-ball {
    width: 100%;
    min-height: calc((var(--vh, 1vh) * 100));
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;

    .app-holder {
        display: flex;
        flex-direction: column;
        max-width: 415px;
        margin: auto;

        img {
            width: 100%; 
            height: 100%;
        }
    }

    p {
        margin: 0;
        padding: 0;
        color: white;
    }

    .align-center {
        margin: 0 auto;
    }

    .align-left {
        margin-right: auto;
    }

    @for $i from 1 through 100 {  //height on percentage
        .h-per-#{$i} {
          height: calc(((var(--vh, 1vh) * 100) - var(--to-be-removed)) * $i/100) !important;
        }
    }

    //font-size
    @for $i from 1 through 100 {
        .fs-#{$i} {
            font-size: 1px * $i  !important;
        }
    }

    //line-height
    @for $i from 1 through 100 {
        .lh-#{$i} {
            line-height: 1px * $i  !important;
        }
    }

    @for $i from 1 through 1000 {
        .w-#{$i} {
          max-width: 1px * $i  !important;
          width: 100% !important;
        }
    }

    @for $i from 1 through 1000 {
        .pt-#{$i} {
            padding-top: 1px * $i  !important;
        }
    }

    @for $i from 1 through 1000 {
        .pr-#{$i} {
            padding-right: 1px * $i  !important;
        }
    }

    @for $i from 1 through 1000 {
        .pb-#{$i} {
            padding-bottom: 1px * $i  !important;
        }
    }

    @for $i from 1 through 1000 {
        .pl-#{$i} {
            padding-left: 1px * $i  !important;
        }
    }
}
