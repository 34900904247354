@import './../../utils.scss';

.choices-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .top-margin {
        margin-top: var(--choices-top-margin);
    }

    .bottom-margin {
        margin-bottom: var(--choices-bottom-margin) !important;
    }
}