@font-face {
    font-family: 'Source Sans Regular';
    src: local('SourceSansPro-Black'), url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format("opentype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Bold';
    src: local('SourceSansPro-Black'), url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf) format("opentype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway Semi-Bold';
    src: local('Raleway'), url(./assets/fonts/Raleway/static/Raleway-SemiBold.ttf) format("opentype");
    font-style: normal;
    font-display: swap;
}
