@use './base' as *;

.goodneighbor {
    width: 100%;
    padding: 0;
    -webkit-font-smoothing: antialiased;

    >div {
        background-color: unset;
        background: rgba(255, 255, 255, 0.25);

        .nav {
            max-width: 415px;
            margin: 0 auto;
        }

        .container .icon {
            color: #fff !important;
        }

        h2 {
            display: none;
        }
    }

    .app-holder {
        display: flex;
        flex-direction: column;
        max-width: 415px;
        margin: auto;
        min-height: calc((var(--vh, 1vh) * 100));
    }

    .white-outline-button {
        border: solid white;
        border-width: 2.5px;
        background-color: #2356A2;
        min-width: 220px;
        height: 50px;
        border-radius: 8px;
        margin-top: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button-text {
        color: white;
        margin: 0;
        font-family: 'Source Sans Bold';
        font-size: 23px;
        line-height: 25px;
        text-align: center;
    }

    p {
        font-family: 'Source Sans Regular';
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: white;
        margin: 0;
    }

    .raleway-text {
        font-family: 'Raleway Semi-Bold';
        font-size: 18px;
        line-height: 25px;
        text-align: center;
    }

    .title {
        font-family: 'Source Sans Bold';
        font-size: 38px;
        line-height: 38px;
        text-align: center;
        color: white;
    }

    .small-title {
        font-family: 'Source Sans Bold';
        font-size: 33px;
        line-height: 38px;
        text-align: center;
        color: white;
    }

    .small-button-text {
        font-size: 19px;
        color: white;
        margin: 0;
        font-family: 'Source Sans Bold';
        line-height: 25px;
        text-align: center;
    }

    .bottom-margin {
        margin-bottom: 56px;
    }


}