@use '../../base';

.swiper-slide {
    .nav-button {
        .nav-button-background {
            h3 { font-family: 'ITC Avant Garde BD'; }
        }
    }
}

.nav-button {
    border-radius: 19px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;

    .inner {
        height: 100%;
    }

    &.event {
        background: linear-gradient(135deg, base.$black_op_04 0%, base.$white_op_04 100%), linear-gradient(0deg, base.$white_grey_2, base.$white_grey_2);
        box-shadow: -10px -10px 8px base.$white_op_07, 5px 5px 10px base.$grey_op_06;
        background-blend-mode: soft-light, normal;
        filter: drop-shadow(0px 2px 1px base.$black_op_02);
        .nav-button-background {
            height: 178px; padding-bottom: 7px;
            transition: all 0.2s;
            h3 { 
                text-align: center; 
                align-self: flex-end; 
                font-size: 38px;
                line-height: 39px;
            }
        }

        .inner {
            box-shadow: inset 0 0 0 base.$white;
            transition: all 0.2s;
        }

      /*   &:active { 
            background: linear-gradient(135deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), #EBECF0;
            box-shadow: unset;
            filter: unset;
            .inner { box-shadow: inset 5px 5px 15px rgba(166, 171, 189, 0.15), inset -10px -10px 21.0807px rgba(166, 171, 189, 0.5); }
            .nav-button-background { opacity: 0.5; }
        } */
    }
    &.event.tickets { .nav-button-background h3 { color: #1769CA; } }
    &.event.guide { .nav-button-background h3 { color: base.$white; filter: drop-shadow(0px 2px 10px rgba(78, 78, 78, 0.5)); } }
    &.event.lineup { .nav-button-background h3 { color: #F04D8D; } }
    &.event.faq { .nav-button-background h3 { color: #1E6667; } }
    &.event.nft { .nav-button-background h3 { color: #115DC2; } }

    &.experience {
        box-shadow: 5px 5px 8px base.$black_op_02, -10px -10px 10px base.$white_grey;
        .nav-button-background {
            height: 220px; 
            padding: 25px;

            h3 {
                font-size: 42px;
                line-height: 36px;
                letter-spacing: 0.4px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 1;
            opacity: 0;
            transition: all 0.2s;
        }
       /*  &:active { 
            &::after { 
                opacity: 1; 
                background: linear-gradient(180deg, rgba(94, 144, 219, 0.28) 49%, rgba(26, 46, 97, 0.82) 100%); 
            }
        } */
        // &.purple-gradient { 
        //     &::after { background: base.$exp_purple_grad;  }
        // }
        // &.ligh-green-gradient { 
        //     &::after { background: base.$exp_ligh_green_grad;  }
        // }
        // &.fade-purple-gradient { 
        //     &::after { background: base.$exp_fade_purple_grad;  }
        // }
        // &.blue-gradient { 
        //     &::after { background: base.$exp_blue_grad;  }
        // }
        // &.green-gradient { 
        //     &::after { background: base.$exp_green_grad;  }
        // }
    }

    &.offer {
        background: linear-gradient(0deg, #FFDA56, #FFDA56);
        height: 72px;
        border-radius: 50%;
        box-shadow: 0px 4px 4px base.$black_op_02, -5px -5px 5px base.$white, inset -2px -2px 3px rgba(0, 0, 0, 0.1), inset 2px 2px 8px base.$white;
        .image-holder { background-size: unset; }
    }

    .nav-button-background {
        display: flex;
        height: 100%;
    }

    h3 {
        width: 100%;
        color: base.$white;
        z-index: 1;
    }
}