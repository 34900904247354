@font-face {
    font-family: 'ITC Avant Garde BD';
    src: local('Century Gothic BD'), url('./assets/fonts/itc-avant-garde-gothic-lt/ITC Avant Garde Gothic LT Bold.otf') format('opentype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter BD';
    src: url("./assets/fonts/Inter/Inter-Bold.eot");
    /* IE9*/
    src: url("./assets/fonts/Inter/Inter-Bold.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("./assets/fonts/Inter/Inter-Bold.woff2") format("woff2"),
        /* chrome、firefox */
        url("./assets/fonts/Inter/Inter-Bold.woff") format("woff"),
        /* chrome、firefox */
        url("./assets/fonts/Inter/Inter-Bold.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("./assets/fonts/Inter/Inter-Bold.svg") format("svg");
    /* iOS 4.1- */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic BD';
    src: local('Century Gothic BD'), url(./assets/fonts/century-gothic/gothicb.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}