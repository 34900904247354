$white: #FFFFFF;
$gold: #906D2A;

@font-face {
    font-family: 'Gesta RG';
    src: url("./assets/fonts/Gesta/gesta-regular.eot"); /* IE9*/
    src: url("./assets/fonts/Gesta/gesta-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/Gesta/gesta-regular.woff2") format("woff2"), /* chrome、firefox */
    url("./assets/fonts/Gesta/gesta-regular.woff") format("woff"), /* chrome、firefox */
    url("./assets/fonts/Gesta/gesta-regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./assets/fonts/Gesta/gesta-regular.svg") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family:"Gesta BD";
    src:url("https://use.typekit.net/af/771564/00000000000000000001417c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/771564/00000000000000000001417c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/771564/00000000000000000001417c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family: 'Inter RG';
    src: url("./assets/fonts/Inter/Inter-Regular.eot"); /* IE9*/
    src: url("./assets/fonts/Inter/Inter-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./assets/fonts/Inter/Inter-Regular.woff2") format("woff2"), /* chrome、firefox */
    url("./assets/fonts/Inter/Inter-Regular.woff") format("woff"), /* chrome、firefox */
    url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("./assets/fonts/Inter/Inter-Regular.svg") format("svg"); /* iOS 4.1- */
    font-style: normal;
    font-display: swap;
}
