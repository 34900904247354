:root {

    --homepage-image-width: 156px;
    --logo-text: 14px;

    @media only screen and (max-height: 650px) {
        --homepage-image-width: calc(((var(--vh, 1vh) * 100) - 150px)/4);
        --logo-text: 12px;
    }

    @media only screen and (max-height: 580px) {
        --logo-text: 10px;
    }

    @media only screen and (max-height: 450px) {
        --logo-text: 8px;
    }
}