@use './base' as *;

.lays {

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    color: $white;
  }

  html {
    height: -webkit-fill-available;
  }


  background-color: #1E1E1E;
  width: 100%;

  .app-holder {
    max-width: 414px;
    margin: 0 auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;

    .card {
      border-radius: 8px;
      overflow: hidden;
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      flex-shrink: 0;
    }
  }

  //f Font sizes
  @for $i from 1 through 100 {
    .fs-#{$i} {
      font-size: 1px * $i;
    }
  }

  // Font Decorations
  .fw-bold {
    font-weight: 700;
  }

  // Alignement
  .text-center {
    text-align: center;
  }

  .center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  // Margins
  @each $abbr,
  $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
    @for $i from 1 through 1000 {
      .m#{$abbr}-#{$i} {
        margin-#{$name}: 1px * $i;
      }
    }
  }

  @each $abbr,
  $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
    @for $i from 1 through 100 {
      .m#{$abbr}-p-#{$i} {
        margin-#{$name}: 1% * $i;
      }
    }
  }

  // Paddings
  @each $abbr,
  $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
    @for $i from 1 through 1000 {
      .p#{$abbr}-#{$i} {
        padding-#{$name}: 1px * $i;
      }
    }
  }

  // Widths and heights
  @for $i from 1 through 1000 {
    .w-#{$i} {
      min-width: 1px * $i  !important;
    }
  }

  @for $i from 1 through 1000 {
    .mw-#{$i} {
      max-width: 1px * $i  !important;
    }
  }

  @for $i from 1 through 1000 {
    .h-#{$i} {
      height: 1px * $i  !important;
      min-height: 1px * $i  !important;
    }
  }

  @for $i from 1 through 1000 {
    .mh-#{$i} {
      height: 1px * $i  !important;
      max-height: 1px * $i  !important;
    }
  }

  @for $i from 1 through 1000 {
    .top-#{$i} {
      top: 1px * $i  !important;
    }
  }

  @for $i from 1 through 1000 {
    .negativeTop-#{$i} {
      top: -1px * $i  !important;
    }
  }

  @for $i from 1 through 1000 {
    .negativeLeft-#{$i} {
      left: -1px * $i  !important;
    }
  }

  // Buttons
  .btn {
    all: unset;
    border-radius: 4px;
    width: 100%;
    display: block;
    padding: 14px 10px;
    box-sizing: border-box;
    width: 335px;
  }

  .btn-inline {
    all: unset;
    border-radius: 4px;
    width: 49% !important;
    padding: 14px 10px;
    box-sizing: border-box;
    max-width: 340px;
    height: 53px !important;
  }

  .btn-gold {
    background-image: url('assets/images/golden_button_bevel 2.png');
    line-height: 1.32;
    padding: 17px 9px 14px;
    background-size: cover;
    width: 337px;
    height: 53px;

    p {
      color: #72500F !important;
    }
  }

  .selfie-after-page {
    .hex-border {
      top: 0px;
    }
  }

  .complete .hex-border {
    top: 100px !important;
    left: 50%;
    margin-left: -175px;
  }

  .complete .btn-gold {
    background-image: url('assets/images/golden_button_bevel 2.png') !important;

    padding: 17px 9px 14px;
    background-size: cover;
    width: 337px;
    height: 53px;

    p {
      color: #72500F !important;
    }

  }

  .btn-outline-gold {
    border: double 2px transparent;
    border-radius: 4px;
    background-image: linear-gradient(#000000, black), linear-gradient(to bottom, #F9B93E, #5F4413);
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: $white;
    height: 53px;
    padding: 0px;
  }

  .btn-outline-gold:hover {
    background-color: $gold;
    color: $white
  }

  .btn-outline-gold:disabled,
  .btn-outline-gold[disabled] {
    border: 1px solid $gold;
    background-color: #cccccc1c;
    cursor: pointer;
  }

  .btn-gold:disabled,
  .btn-gold[disabled] {
    border: 1px solid $gold;
    background-color: #302511;
    cursor: pointer;
  }

  .nextCountry {
    position: absolute;
    bottom: 95px;
    width: 100%;
    left: 0px;
    right: 0px;
  }

  .hex-border {
    position: absolute;
    z-index: 3;
    width: 350px;
    height: 327px;
  }

  .flex-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .backButton {
    align-self: flex-start;
  }

  *::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    /* make scrollbar transparent */
  }

  .customheight-fixed {
    height: calc((var(--vh, 1vh) * 100) - 100px);
  }

  .homeCustomHeight {
    height: calc((var(--vh, 1vh) * 100) - var(--custompadding)); //THIS MAKES IT SCROLL ON CHROME
  }

  .customheightinner {
    min-height: calc((var(--vh, 1vh) * 100) - var(--custompadding) - 5.2rem);
  }

  .imframeheight {
    min-height: calc((var(--vh, 1vh) * 100) - 178px);
  }

  h2 {
    font-family: 'Trebuchet MS', sans-serif !important;
    font-size: 24px;
  }

  .fak:after {
    content: "Back";
    font-family: 'Gesta RG' !important;
    margin-left: 10px;
    line-height: 1;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #ffffff;
  }

  .fak {
    top: calc(-42px + var(--custompadding));
    font-size: 14px !important;
    position: absolute;
    display: block;
    padding: 10px 5px;
  }

  .footer-links a {
    color: white !important;
  }

  div .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 414px;
    margin: 0 auto;
  }

  .nav h4 {
    top: calc(-77px + var(--custompadding));
    position: absolute;
    right: 0;
    width: 70px;
    text-align: right;
    padding-top: 4px !important;
    font-family: 'Gesta RG' !important;
    color: #ffffff;
  }

  .bottom-pagination {
    position: relative !important;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin-bottom: 2px !important;
    }

    .swiper-pagination-bullet-active {
      width: 12px;
      height: 12px;
      margin-bottom: 0px !important;
    }
  }

}

.custompadding {
  padding-top: var(--custompadding);
}

.customheight {
  min-height: calc((var(--vh, 1vh) * 100) - var(--custompadding));
}