.bg-dark-prizes {
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: rgba(233, 186, 99, 0.16);
    border-radius: 8px;
    height: calc((var(--vh, 1vh) * 100) - 290px) !important;
}

.notice-text {
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 40px;
}

.images-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 16px;
    align-content: center;
    clear: both;

    ul {
        background: rgba(17, 17, 17, 0.75);
        padding: 0px;
        display: flex;
        justify-content: left;
        margin: 0 0 35px 0;
        width: 100%;

        li {
            list-style: none;
            float: left;

            button {
                border: none;
                background: none;
                font-family: 'Trebuchet MS';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                align-items: center;
                letter-spacing: 4px;
                margin: 6px 0px 6px 12px;
                padding: 3px 5px 3px 0;
                color: #fff;

            }

            .Active {
                color: #E9BA63;
                border-bottom: 2px solid #E9BA63;
            }
        }
    }

}

.category-title {
    font-family: 'Trebuchet MS';
    font-weight: 800;
    font-size: 8px;
    letter-spacing: 4px;
    color: #E9BA63;
    line-height: 9px;
}

.category-title::before {
    content: "";
    display: inline-block;
    margin: 0;
    height: 8px;
    width: 50px;
    background-color: #E9BA63;
    margin-right: 6px;
}

.brand-title {
    font-family: 'Trebuchet MS';
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 4px;
    color: #FFFFFF;
    line-height: 29px;
    align-self: center;
}

hr {
    border-top: 0px;
    width: 90%;
    border-color: #ECB143;
}

.prize-img {
    align-self: center;
    max-width: 100%;
    padding: 10px;
    margin: 20px 0 0 0;
    width: 100%;
}

.autoheight {
    height: auto !important;
    width: 100%;
}