.ellipse {
  box-sizing: border-box;

  width: 218px;
  height: 218px;

  border-radius: 200px;

  border: 1px dashed #FFFFFF;

  background-image: url('../../../assets/images/Soccer_ball_Solo_00000\ 3.png');
  background-position: center;

  filter: drop-shadow(0px 4px 24px #906D2A);
}

.circle {
  position: absolute;
  z-index: 2;
}

.ball {
  width: 218px;
  height: 218px;
  background-image: url('../../../assets/images/Soccer_ball_Solo_00000 3.png');
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;

  bottom: 30px;
  z-index: 4;

}

.glow {
  width: 300px;
  height: 300px;
  background-image: url('../../../assets/images/Ellipse 441.png');
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 3;
}

.glowSmall {
  width: 30px;
  height: 30px;
  background-image: url('../../../assets/images/Ellipse 441.png');
  background-size: cover;
  position: absolute;
  z-index: 3;
}

.pos1 {
  opacity: 0;
  bottom: 1000px;
  left: -1500px;
}

.pos2 {
  opacity: 0;
  bottom: 1500px;
  left: -750px;
}

.pos3 {
  opacity: 0;
  bottom: 2000px;
  left: 0px;
}

.pos4 {
  opacity: 0;
  bottom: -1500px;
  left: -750px;
}

.pos5 {
  opacity: 0;
  bottom: -1000px;
  left: 1500px;
}


.ball-circle {
  width: 218px;
  height: 218px;
  background-color: rgba(255, 255, 255, 0.08);
  border: 2px dashed rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  bottom: 30px;

}


.off {
  display: block;
}

.lays .next-off {
  display: none;
}

.share-active {
  display: none;
}

.boxbottom {
  position: absolute;
  bottom: 25px;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  z-index: 4;
}

.passwrapper {
  height: calc((var(--vh, 1vh) * 100) - 180px);
}

.animationWrapper {
  position: relative;
  height: calc((var(--vh, 1vh) * 100) - 180px);
  overflow: hidden;

  video {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
  }
}

.tap-text {
  font-family: 'Trebuchet MS';
  font-weight: 400;
  font-size: 24px;
  color: white;
  align-self: center;
}

.show- {
  display: none;
}

.show-false {
  display: none;
}

.show-true {
  display: block;
}

.dark-overlay {
  position: fixed;
  width: 100%;
  /* Full width (cover the whole page) */
  height: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 255, 0, 0.4);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}