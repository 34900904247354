@use '../../base';

.highlights-page {

    .page-content {
        position: relative;

        .image-holder { 
            height: 177px;
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 12px;
        }

        .highlights-element {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 22px 0 28px 0;
            border-bottom: 2px solid rgba(163, 173, 178, 0.5);
            
            &:last-of-type { border-bottom: unset; }

            button {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 37px;
                height: 37px;
                background: #F0F0F3;
                box-shadow: -3px -3px 10px base.$white_op_05, 3px 3px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 2px base.$black_op_01, inset 2px 2px 4px base.$white;
                border-radius: 50%;

                .icon {
                    font-size: 22px;
                    color: base.$grey;
                }
            }

            h5 {
                width: 100%;
                font-family: 'Century Gothic BD';
                font-size: 21px;
                line-height: 26px;
                letter-spacing: -0.03em;
                color: #222222;
                cursor: pointer;
            }
        }

    }

}