.match-frame {
    background-image: url('../../assets/images/Group 1171274776-1.png');
    background-position: center;
}

.overlay-gold {
    position: fixed;
    width: 100%;
    /* Full width (cover the whole page) */
    height: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.6) 0), url("../../assets/images/BP_POLYBALL_v02 1.png");
    background-repeat: no-repeat;
    background-position: bottom;
}

.overlay-ball {
    position: fixed;
    width: 100%;
    /* Full width (cover the whole page) */
    height: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(0, 0, 0, 0.6) 0), url("../../assets/images/BP_POLYBALL_v03_0005 1.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.game-iframe {
    width: 100%;
    height: 370px;
    border: 0;
}

.pregame {
    padding: 0px;
    background-image: url('../../assets/images/pre-game-bg.png');

    .page-wrapper {
        margin: 0 16px;
    }
}

.img-height-daily {
    max-height: calc(var(--vh, 1vh) * 95 - 430px) !important;
    max-width: 100%;
    margin: auto;
}