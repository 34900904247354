.landing-page {
    background-image: url('../../assets/images/FIFA_v04JustFIELD\ 2.jpg');
    background-position: center;
    background-size: cover;

    .page-wrapper {
        margin: 0px 16px;
    }
}

* {
    font-family: "Gesta RG";
}

.center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}