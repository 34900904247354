@use '../../base';

.home-page {
    display: flex;
    flex-direction: column;

    .module {
        h4 { margin-bottom: 15px; }
    }

    .wallet .inner {
        padding-left: 28px;
    }
}