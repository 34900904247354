@use '../../base';

$background_img: url("http://cms.vatom.com/wp-content/uploads/2022/05/unsplash_0bBi5rjwS_s-13.png");

.music-cta {
    .button {
        background: linear-gradient(135deg, base.$black_op_04 0%, base.$white_op_04 100%), base.$red;
        border: 0.7px solid base.$white_op_04;
        box-shadow: -8px -8px 16px base.$white, 5px 5px 8px rgba(166, 171, 189, 0.5);
        color: base.$white;

        span {
            font-size: 21px;
            line-height: 30px;
            margin: 9px 0;
        }

        .icon {
            font-size: 21px;
            color: base.$white;
            margin: 0;
        }

      /*   &:active { 
            color: #DD5E5E;
            .icon { color:#DD5E5E }
        } */
    }
    h4 {
        font-family: "Century Gothic BD";
        font-size: 30px !important;
        line-height: 37px !important;
    }
}