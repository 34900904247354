@use '../../base';

.meet-the-artist-page {

    .meet-the-artist-content {
        .image-holder {
            height: 313px;
            margin-bottom: 24px;
            border: 1px solid base.$black;
            filter: base.$drop_shadow_w08_b04;
            border-radius: 20px;
        }
    
        p {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
        }
    
        .button {
            .icon { margin-left: 30px; }
        }
    }

}