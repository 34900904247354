@use '../../base' as *;

.jingle-ball {
    .post {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 321px;
        padding: 14px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 14px;
        box-shadow: 3px 3px 5px rgba(82, 82, 82, 0.65);

        .title {
            font-family: 'ITC Avant Garde BD';
            font-size: 30px;
            line-height: 32px;
            letter-spacing: -0.01em;
        }

        .subtitle,
        .button-text {
            font-family: 'Inter BD';
            line-height: 15px;
            letter-spacing: 0.03em;
        }

        .cta {
            display: flex;
            align-items: flex-end;

            &.big {
                margin-bottom: 6px;

                .button-text {
                    @extend .title;
                }

                .small-arrow {
                    width: 10px;
                    height: 16px;
                    margin-bottom: 5px;
                    margin-left: 24px;
                }
            }
        }

        .subtitle {
            font-size: 15px;

            &.big {
                font-size: 16px;
                margin-top: 12px;
            }
        }

        .button-text {
            font-size: 16px;
        }

        .small-arrow {
            height: 12px;
            width: 7px;
            margin-left: 6px;
            margin-bottom: 2px;
        }

        .left {
            margin-right: auto;
        }

        .right {
            margin-left: auto;
            text-align: right;
        }

        .center {
            align-self: center;
            text-align: center;
        }
    }

}