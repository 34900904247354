@import './../../utils.scss';

.congestion-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 45px;
    margin-right: 45px;
}

.small-bottom-margin {
    margin-bottom: var(--congestion-small-bottom-margin) !important;
}

.small-top-margin {
    margin-top: 37px !important;
}