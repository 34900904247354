@import './../../utils.scss';

.deloitte {

    .deloitte-home-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100vh;
        background-image: url('./../../assets/images/background.png');
        background-size: cover;

        .logo-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 32px;
        }

        .logo-text {
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: var(--logo-text);
            line-height: 16px;
            text-align: center;
            margin: 0;
        }

        .image-container {
            display: flex;
            flex-direction: column;

        }

        img:hover {
            cursor: pointer;
        }

        img {
            width: var(--homepage-image-width) !important
        }

        .image-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    }
}