@use '../../base';

.photo-collections {

    .collection {
        border-radius: 18px;
        height: 172px;
        margin-bottom: 26px;
        display: flex;
        padding: 18px 12px;
        position: relative;
        filter: base.$drop_shadow_w08_b02;
        cursor: pointer;

        &.top-left { align-items: flex-start; justify-content: flex-start; }
        &.top-right { align-items: flex-start; justify-content: flex-end; }
        &.bottom-left { align-items: flex-end; justify-content: flex-start; }
        &.bottom-right { align-items: flex-end; justify-content: flex-end; }

        h1 {
            color: base.$white;
            font-family: 'ITC Avant Garde BD';
            font-size: 42px;
            line-height: 45px;
        }
    }
}