.selfie-before-page, .selfie-before-page-black {
    background-image: url('../../assets/images/FIFA_v04JustFIELD\ 2.jpg');
    background-position: center;
    background-size: cover;
    
    display: flex;

    .page-wrapper {
        margin: 0 16px;
        width: 100%;
    }
    .overlay {
        position:fixed;
        width: 100%; /* Full width (cover the whole page) */
        height: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(0,0,0,0.6) 0),url("../../assets/images/BP_POLYBALL_v03_0005\ 1.png");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
    }

}
.selfie-before-page-black {
    background-image: none;
    background-color: #000;

}
.selfie-after-page {
    // background-color:#161616;
    min-height: calc((var(--vh, 1vh) * 100) - 100px);
    display: flex;
  
    align-items: center;
    justify-content: center;

    .page-wrapper {
        margin: 0 16px;
        z-index: 2;
    }

    // .overlay {
    //     position:fixed;
    //     width: 100%;
    //     height: inherit;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     background-color:#161616;
      
    // }
}

.front {
    z-index: 2;
    position:relative;
}

.hex {
    overflow: hidden;
    position: absolute;
}
.mustContent {
    bottom: 95px;
    position: absolute;
    left:0;
    right:0;
    padding: 12px;
}

.img-height {
    max-height: calc((var(--vh, 1vh) * 95) - 400px) !important;
}

.coin-image{
    width: auto;
    height: 100%;
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: calc(var(--vh, 1vh) * 100 - 460px);
    
}

.wallet-icon{
   max-width: 80px;
   display: block;
   margin: 20px auto;
//    margin-left: calc(((var(--vw, 1vw) * 100) - 80px)/2); 
}
.align-top{
    align-items: start;
}
