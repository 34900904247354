@use '../../base' as *;

.jingle-ball {
    .home {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: calc((var(--vh,1vh) * 100) - var(--to-be-removed));
        padding-left: 24px;
        padding-right: 24px;
        background-image: url('https://cms.vatom.com/wp-content/uploads/2022/10/Home-Background-1-scaled.jpeg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        gap: 24px;
    }

    .logo-img {
        width: 173px;
        height: 222px;
        margin-top: 30px;
    }
}
