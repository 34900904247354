@use '../../base.scss' as *;

.explore-landing-page {
    padding: 0px;
    background-image: url('../../assets/images/background.png');
    height: calc(var(--vh, 1vh) * 100 - var(--custompadding));
    background-size: cover;
 
    .page-wrapper {
        margin: 0 16px;
    }
}

.animationpage
{
    position: relative;
    background-image:none !important;
}
.explore-animation-frame {
    // position: relative;
    // top: 0;
    width: 100%;
    border: none;
    box-shadow: none !important;
    // background-color: green;
}

.gold-ball{
    height: calc((var(--vh, 1vh) * 95) - 340px) !important;
    width: 100%;
    object-fit: cover;
    object-position: 0% 100%;
    margin-bottom: 20px;
}

