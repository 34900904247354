@use '../../base';

.ar-shop-single-page {

    .ar-shop-header {
        padding: 32px;
        height: 529px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        h2, h5 { 
            color: base.$white; 
            width: 60%; 
            margin-bottom: 11px; 
            filter: drop-shadow(4px 4px 10px base.$black_op_01);
        }
    }

    .page-content {
        margin: 22px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 30px;
        }

        .button {
            margin-bottom: 15px;
            .icon {
                margin-left: 20px;
            }
        }

        .check-box-holder {
            position: relative;
            input { display: none; }
            .check-box { 
                cursor: pointer; 
                position: absolute;
                left: 0;
                width: 26px;
                height: 26px;
                border: 1px solid base.$light_black;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    position: absolute;
                    color: base.$white;
                    display: none;
                }
                &.checked {
                    .icon { display: block; }
                    background-color: base.$light_black;
                }
            }
            
            label {
                padding-left: 36px;
                display: block;
                font-size: 12px;
                line-height: 26px;
                cursor: pointer;
            }
        }
    }

}