@use '../../base';

.faq-page {

    .faq-header {
        height: 230px;
        display: flex;
        align-items: flex-end;
        padding: 10px 32px;

        h1 {
            color: base.$white;
            text-shadow: 5px 4px 10px base.$black_op_05;
            font-family: 'ITC Avant Garde BD';
            font-size: 28px;
            line-height: 36px;
        }
    }

    .faq-element {
        padding-bottom: 15px;
        border-bottom: 1px solid base.$white_grey_3;
        margin: 10px 0;

        &:first-of-type {
            margin: 19px 0 10px 0;
        }

        h6, p { color: base.$middle_black; }
        h6 {
            font-size: 18px;
            line-height: 24px;
            font-family: 'Century Gothic BD';
            letter-spacing: 0.1px;
            margin-bottom: 4px;
        }
        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            font-family: 'Century Gothic MD';

            a {
                color: base.$purple;
                font-weight: 600;
            }
        }
    }

}